import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  convertDateTimeFromServer,
  convertDateTimeToServer,
  displayDefaultDateTime,
  displayDefaultDateTimePlusOneMonth
} from 'app/shared/util/date-utils';
import { getEntities as getRateSchedules } from 'app/entities/rate-schedule/rate-schedule.reducer';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import {
  getEntity,
  updateEntity,
  createEntity,
  reset,
} from '../../entities/validation/validation.reducer';
import { toast } from 'react-toastify';
import { RateType } from 'app/shared/model/enumerations/rate-type.model';

export interface IValidationProps {
  id?: any;
  hidePopup: () => void;
}

export default function ValidationCreateUpdate(props: IValidationProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = props;
  const isNew = id === undefined;

  const validationEntity = useAppSelector(state => state.validation.entity);
  const loading = useAppSelector(state => state.validation.loading);
  const updating = useAppSelector(state => state.validation.updating);
  const updateSuccess = useAppSelector(state => state.validation.updateSuccess);
  const rateSchedules = useAppSelector(state => state.rateSchedule.entities);

  const [filteredRateSchedules, setFilteredRateSchedules] = useState([]);

  const defaultValues = () =>
    isNew
      ? {
          startTime: displayDefaultDateTime(),
          endTime: displayDefaultDateTimePlusOneMonth(),
        }
      : {
          ...validationEntity,
          startTime: convertDateTimeFromServer(validationEntity.startTime),
          endTime: convertDateTimeFromServer(validationEntity.endTime),
          rateScheduleId: validationEntity.rateSchedule ? validationEntity.rateSchedule.id : '',
        };

  // Filter schedules where endInstant is greater than the current time
  useEffect(() => {
    if (rateSchedules) {
      const currentTime = new Date().toISOString();
      
      // Filter schedules by endInstant
      let filtered = rateSchedules.filter(rateSchedule => rateSchedule.endInstant > currentTime);

      // If there's an existing schedule on the validation entity, ensure it's included in the list
      if (validationEntity.rateSchedule && !filtered.some(rs => rs.id === validationEntity.rateSchedule.id)) {
        filtered = [...filtered, validationEntity.rateSchedule];
      }

      setFilteredRateSchedules(filtered);
    }
  }, [rateSchedules, validationEntity]);

  useEffect(() => {
    dispatch(getRateSchedules({ query: `rateType.equals=${RateType.VALIDATION}` }));
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      window.location.href = '/pages/validation';
      props.hidePopup();
    }
  }, [updateSuccess]);

  const handleClose = () => {
    navigate('/pages/validation');
    props.hidePopup();
  };

  const saveEntity = values => {
    const currentDateTime = new Date().toISOString();

    const multipleEntries = (document.getElementById('multipleEntries') as HTMLInputElement).checked;
    values.multipleEntries = multipleEntries;

    if (values.endTime < currentDateTime) {
      toast.error('Please enter a time greater than current time');
      return;
    }

    if (values.startTime === values.endTime || values.startTime > values.endTime) {
      toast.error('End time should be greater than start time');
      return;
    }

    values.startTime = convertDateTimeToServer(values.startTime);
    values.endTime = convertDateTimeToServer(values.endTime);
    const entity = {
      ...validationEntity,
      ...values,
      isUsed: false,
      rateScheduleId: Number(values.rateScheduleId),
      rateSchedule: rateSchedules.find(rateSchedule => rateSchedule.id === Number(values.rateScheduleId)),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
            <ValidatedField
              label="Email"
              id="validation-email"
              name="email"
              data-cy="email"
              type="text"
              className="customer-account-label"
              required={true}
            />
            <ValidatedField
              label="Description"
              id="description"
              name="description"
              data-cy="description"
              type="text"
              className="customer-account-label"
              maxLength={25}
              required={true}
            />
            <ValidatedField
              label="Start Time"
              id="validation-startTime"
              name="startTime"
              data-cy="startTime"
              type="datetime-local"
              placeholder="YYYY-MM-DD HH:mm"
              className="customer-account-label"
            />
            <ValidatedField
              label="End Time"
              id="validation-endTime"
              name="endTime"
              data-cy="endTime"
              type="datetime-local"
              placeholder="YYYY-MM-DD HH:mm"
              className="customer-account-label"
            />
            <ValidatedField
              label="Count"
              id="validation-count"
              name="count"
              data-cy="count"
              type="number"
              min="1"
              max="1000"
              className="customer-account-label"
              required={true}
            />
            <div style={{ display: 'flex', gap: '10px' }}>
              <label htmlFor="multipleEntries">Allow multiple entries</label>
              <ValidatedField
                id="multipleEntries"
                name="multipleEntries"
                data-cy="multipleEntries"
                type="checkbox"
                defaultChecked={validationEntity.multipleEntries || false}
              />
            </div>

            <ValidatedField
              label="Rate Schedule"
              id="rateScheduleId"
              name="rateScheduleId"
              data-cy="rateScheduleId"
              type="select"
              className="customer-account-label"
              required
            >
              {filteredRateSchedules.map(rateSchedule => (
                <option value={rateSchedule.id} key={rateSchedule.id}>
                  {rateSchedule.name}
                </option>
              ))}
            </ValidatedField>

            <div style={{ display: 'flex', padding: '30px', justifyContent: 'center' }}>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" onClick={handleClose} replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </div>
          </ValidatedForm>
        </Col>
      </Row>
    </div>
  );
}
