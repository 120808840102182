import React, { useState, useEffect } from 'react';
import { Button, Modal, Card, Table, Spinner } from 'react-bootstrap'; // Import Spinner from react-bootstrap
import { formatDate } from 'app/shared/util/date-utils';
import './style.css';
import { IParkingSession } from 'app/shared/model/parking-session.model';
import jsPDF from 'jspdf';
import ZoomableImageWithFallback from 'app/components/ZoomableImageWithFallback';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from 'app/entities/parking-session/parking-session.reducer';
import { getEntities } from '../../entities/business-details/business-details.reducer';
import { useConfigContext } from 'app/contexts/ConfigContext';
import { filterMapFields } from 'app/config/constants';
import { IEvent } from 'app/shared/model/event.model';
import { getEnterImage, getExitImage, getFallbackImage, getFallbackImageJpg } from 'app/session-images';
import dayjs from 'dayjs';

import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import InnerImageZoom from 'react-inner-image-zoom'

import { Zoom } from 'react-toastify';

interface ShowLPRMoadalProps {
  sessionId?: string;
  showModal?: boolean;
  confirmMethod?: any;
  cancelMethod?: any;
  titleText?: string;
  detailedText?: string | React.ReactNode;
  confirmButtonText?: string;
  confirmButtonStyle?: string;
  noButtons?: boolean;
  additionalClass?: string;
  selectedLane?: IEvent;
}

export const ShowLPRMoadal = (props: ShowLPRMoadalProps) => {
  console.log("selected lane", props?.selectedLane)
  const config = useConfigContext();
  const dispatch = useAppDispatch();

  // Local state to track image loading errors
  const [entryImgError, setEntryImgError] = useState(false);
  const [exitImgError, setExitImgError] = useState(false);

  // Local loading state
  const [loading, setLoading] = useState(true);

  // Fetch the parking session from Redux
  const localParkingSession: IParkingSession = useAppSelector(state => state.parkingSession.entity);
  const businessDetailsList = useAppSelector(state => state.businessDetails.entities);
  const businessDetails = businessDetailsList[0];
  // Image URLs based on the fetched sessionId
  const entryImgUrl = getEnterImage(config.LPR_IMAGES_BASE_URL, localParkingSession?.sessionId);
  const exitImgUrl = getExitImage(config.LPR_IMAGES_BASE_URL, localParkingSession?.sessionId);
  const fallbackImgUrl = getFallbackImage(config.LPR_IMAGES_BASE_URL);
  const getDuration = (entryTime: string | null, exitTime: string | null) => {
    if (!entryTime || !exitTime) return 'N/A';
    const entry = dayjs(entryTime);
    const exit = dayjs(exitTime);
    return exit.diff(entry, 'minutes');
  }

  const entryDetails = filterMapFields(new Map([
    ['License Plate', (localParkingSession?.plate?.toString() || 'N/A')],
    ['Time', formatDate(localParkingSession?.entryTime?.toString()) || 'N/A'],
    ['Access Type', localParkingSession?.paymentType?.toString() || 'N/A'],
    ['Access Id', localParkingSession?.paymentMethodId?.toString() || 'N/A'],
    ['Lane', localParkingSession?.entryLaneId?.toString() || 'N/A'],
    ['Session', localParkingSession?.sessionId?.toString() || 'N/A'],
    ['Reason', localParkingSession?.reason?.toString() || 'N/A'],
    ['Account Id', localParkingSession?.accountId?.toString() || 'N/A'],
    ['Payment Account Id', localParkingSession?.paymentAccountId?.toString() || 'N/A'],
    ['Bay Id', localParkingSession?.bayId?.toString() || 'N/A'],
    ['Validation', localParkingSession?.batchId ? ((localParkingSession?.batchId?.toString()) + " : " + (localParkingSession?.validationCounter?.toString())) : 'N/A'],
  ]), config.LPR_ENTER_FIELDS);

  const exitDetails = filterMapFields(new Map([
    ['License Plate', (localParkingSession?.exitPlate?.toString() || 'N/A')],
    ['Fee ($)', localParkingSession?.fee ? (localParkingSession.fee / 100).toFixed(2).toString() : 'N/A'],
    ['Paid ($)', localParkingSession?.paid?.toString() || 'N/A'],
    ['Time', formatDate(localParkingSession?.exitTime?.toString()) || 'N/A'],
    // compute duration
    ['Duration (minutes)', getDuration(localParkingSession.entryTime, localParkingSession.exitTime) || 'N/A'],
    ['Lane', localParkingSession?.exitLaneId?.toString() || 'N/A'],
    ['Access Type', localParkingSession?.paymentType?.toString() || 'N/A'],
    ['Access Id', localParkingSession?.paymentMethodId?.toString() || 'N/A'],
    ['Session', localParkingSession?.sessionId?.toString() || 'N/A'],
    ['Reason', localParkingSession?.reason?.toString() || 'N/A'],
    ['Account Id', localParkingSession?.accountId?.toString() || 'N/A'],
    ['Payment Account Id', localParkingSession?.paymentAccountId?.toString() || 'N/A'],
    ['Bay Id', localParkingSession?.bayId?.toString() || 'N/A'],
    ['Validation', localParkingSession?.batchId ? ((localParkingSession?.batchId?.toString()) + " : " + (localParkingSession?.validationCounter?.toString())) : 'N/A'],])
    , config.LPR_EXIT_FIELDS);
  useEffect(() => {
    console.log("props.showModal", props.showModal)
    console.log("props.sessionId", props.sessionId)
    if (props.showModal && props.sessionId) {
      setLoading(true); // Start loading
      dispatch(getEntity(props.sessionId)).finally(() => setLoading(false)); // Stop loading when data is fetched
      dispatch(getEntities({}));
    }
  }, [props.showModal, props.sessionId, dispatch]);

  const handleClose = () => {
    props.cancelMethod();
    setEntryImgError(false);
    setExitImgError(false);
    setLoading(true);
  };

  const formatBusinessDetails = (details) => {
    // Filter out any empty fields
    const fields = [
      details.name,
      details.siteName,
      details.abnBusinessName ? `${details.abnBusinessName}` : null
    ].filter(Boolean); // Remove falsy values (e.g., null, undefined, empty strings)

    // Join the non-empty fields with pipes
    return fields.join(' | ');
  };

  const formatAddress = (details) => {
    console.log("details", details)
    // Filter out any empty fields
    const fields = [
      details.addressLine1,
      details.addressLine2,
      details.addressLines3
    ].filter(Boolean).filter(f => f.trim() !== ''); // Remove falsy values (e.g., null, undefined, empty strings)
    console.log("fields", fields)
    // Join the non-empty fields with pipes
    return fields.join(', ');
  };


  // Function to convert image URL to base64
  async function convertImageToBase64(imageUrl: string): Promise<{ base64: string; width: number; height: number } | null> {
    const response = await fetch(imageUrl, { mode: 'cors' });
    const blob = await response.blob();
    const base64 = await new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string); // Cast reader.result to string
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });

    // Create an image element to get dimensions
    const img = new Image();
    img.src = base64;
    await new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
    });

    return {
      base64: base64.split(',')[1], // Remove the data URL prefix
      width: img.width,
      height: img.height,
    };

  }
  const handleDownloadPDF = async () => {
    if (!localParkingSession) return;

    const doc = new jsPDF('p', 'mm', 'a4');

    if (businessDetails) {
      doc.setFontSize(16);
      doc.setFont(undefined, 'bold');

      doc.text(formatBusinessDetails(businessDetails), 10, 10);
      doc.setFontSize(16);
      doc.text(formatAddress(businessDetails), 10, 20);
      doc.setFont(undefined, 'normal');
    }

    const fetchImageWithFallback = async (url, fallbackUrl) => {
      try {
        return await convertImageToBase64(url);
      } catch {
        console.warn(`Failed to load image from ${url}. Using fallback image.`);
        return await convertImageToBase64(fallbackUrl);
      }
    };

    const drawCard = async (label, imageUrl, fields, startY, fallbackImgUrl) => {
      // Set the title at the original position
      doc.setFontSize(14);
      doc.text(label, 10, startY);

      let imageHeight = 0;
      let textPositionY = startY; // Align table start with the label Y position
      const imageMaxHeight = 60;
      const imageMaxWidth = 90;

      const image = await fetchImageWithFallback(imageUrl, fallbackImgUrl);
      if (image) {
        const { base64, width, height } = image;
        const { resizedWidth, resizedHeight } = getAspectRatioFit(width, height, imageMaxWidth, imageMaxHeight);
        imageHeight = resizedHeight;

        doc.addImage(base64, 'JPEG', 10, textPositionY + 5, resizedWidth, resizedHeight);
      }

      let maxContentHeight = imageHeight;
      const rowHeight = 10; // Standard row height to center text vertically
      const textPositionXKey = 112; // Adjusted X position for keys for better centering
      const textPositionXValue = 147; // Adjusted X position for values
      doc.setFontSize(12);
      console.log("fields", fields)
      // Draw table rows for key-value pairs
      Array.from(fields).forEach(([key, value], index, array) => {
        // Draw the top horizontal line for the first row only
        if (index === 0) {
          doc.line(110, textPositionY - 4, 200, textPositionY - 4); // First row's top line
        }

        // Split the key text to fit within the column and wrap at spaces
        const wrappedKey = doc.splitTextToSize(`${key}`, 28); // Adjust width to wrap keys

        // Draw each line of the wrapped key
        wrappedKey.forEach((line, lineIndex) => {
          doc.text(line, textPositionXKey, textPositionY + (lineIndex * 8));
        });

        // Split and draw the value text to fit in the value column
        const wrappedValue = doc.splitTextToSize(`${value}`, 50); // Wrap text to fit in the value column
        wrappedValue.forEach((line, lineIndex) => {
          doc.text(line, textPositionXValue, textPositionY + (lineIndex * 8));
        });

        // Adjust Y position for the row height based on the largest number of lines between key and value
        const numLines = Math.max(wrappedKey.length, wrappedValue.length);
        textPositionY += numLines * 8;

        // Draw a horizontal line at the bottom of the current row
        doc.line(110, textPositionY - 3, 200, textPositionY - 3); // Bottom line for the current row

        // Only add padding between fields, not after the last field
        if (index < array.length - 1) {
          textPositionY += 2; // Add minimal 2 units of space between fields
        }

        // Keep track of max content height for card size
        maxContentHeight = Math.max(maxContentHeight, textPositionY - startY);
      });

      // Draw vertical lines for table columns
      const tableTopY = startY; // Align with the label's Y position
      const tableBottomY = textPositionY; // Align with the last row's bottom line

      // Left vertical line
      doc.line(110, tableTopY - 4, 110, tableBottomY - 3);
      doc.line(145, tableTopY - 4, 145, tableBottomY - 3);

      // Right vertical line (end of value column)
      doc.line(200, tableTopY - 4, 200, tableBottomY - 3);

      // Draw the card rectangle around the entire content
      doc.rect(5, startY - 15, 200, maxContentHeight + 20); // Draw card around image and text

      // Return the Y position for the next section
      return startY + maxContentHeight + 20;
    };









    // Use the drawCard function for entry and exit sections with fallback images
    let nextY = 40;


    nextY = await drawCard('Entry', entryImgUrl, entryDetails, nextY, getFallbackImageJpg(config.LPR_IMAGES_BASE_URL));
    nextY = await drawCard('Exit', exitImgUrl, exitDetails, nextY, getFallbackImageJpg(config.LPR_IMAGES_BASE_URL));

    doc.save('parking-session.pdf');
  };


  const getAspectRatioFit = (srcWidth: number, srcHeight: number, maxWidth: number, maxHeight: number) => {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    const resizedWidth = srcWidth * ratio;
    const resizedHeight = srcHeight * ratio;
    const offsetX = (maxWidth - resizedWidth) / 2;
    const offsetY = (maxHeight - resizedHeight) / 2;
    return { resizedWidth, resizedHeight, offsetX, offsetY };
  };


  const parkingSessionTable = (fields: Map<string, string>) => {
    return (
      <Table striped bordered hover size="sm" className="mb-0">
        <tbody>
          {Array.from(fields).map(([key, value]) => (
            <tr key={key}>
              <td><strong>{key}</strong></td>
              <td style={{ wordWrap: 'break-word', whiteSpace: 'normal', maxWidth: '200px' }}>
                {value}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
  if (props.showModal && loading) return <>Loading...</>
  return (
    <Modal show={props.showModal} centered className="popup-container" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{'Parking session details'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="business-details-header">
          {businessDetails && (
            <>
              <div className="business-info" style={{ fontWeight: 'bold', fontSize: '18px' }}>
                {formatBusinessDetails(businessDetails)}
              </div>
              <div className="business-address" style={{ fontSize: '14px', marginTop: '5px' }}>
                {formatAddress(businessDetails)}
              </div>
              <div style={{ position: 'absolute', top: '20px', right: '10px', display: 'flex', gap: '10px' }}>
                <Button className={`me-1 btn ${props.confirmButtonStyle}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleDownloadPDF()
                  }}>
                  {props.confirmButtonText || 'Download PDF'}
                </Button>
              </div>
              <br />
            </>
          )}
        </div>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div className="container" id="lprModalContent">
            {!localParkingSession.exitLaneId && <div><h3 style={{
              display: 'flex',
              alignContent: 'center',
              placeContent: 'center',
            }}>
              Active Session
            </h3>
            </div>}

            <div className="row mb-4">
              <div className="col-md-12">
                {showEventDetails('Entry', props.selectedLane, localParkingSession, entryImgError, fallbackImgUrl, entryImgUrl, setEntryImgError, parkingSessionTable, entryDetails)}
              </div>
            </div>

            {localParkingSession.exitLaneId ?
              <div className="row">
                <div className="col-md-12">
                  {showEventDetails('Exit', props.selectedLane, localParkingSession, exitImgError, fallbackImgUrl, exitImgUrl, setExitImgError, parkingSessionTable, exitDetails)
                  }
                </div>
              </div> : <></>
            }

          </div>
        )}
      </Modal.Body>

    </Modal>
  );
};

export default ShowLPRMoadal;



function showEventDetails(title:String, selectedLane: IEvent, localParkingSession: IParkingSession, imgError: boolean, fallbackImgUrl: string, imgUrl: string, setImgError: React.Dispatch<React.SetStateAction<boolean>>, parkingSessionTable: (fields: Map<string, string>) => React.JSX.Element, details: Map<string, string>): React.ReactNode {
  if (selectedLane && selectedLane.laneId && selectedLane.laneId !== localParkingSession.entryLaneId) {
    return <></>
  }
  const img = imgError ? fallbackImgUrl : imgUrl;
  console.log("img", img)
  return localParkingSession.entryLaneId && <Card>
    <Card.Header style={{ color: 'white' }}>{title}</Card.Header>
    <Card.Body>
      <div className="row">
        <ZoomableImageWithFallback
          imageUrl={imgUrl}
          fallbackUrl={fallbackImgUrl}
          isError={imgError}
          onError={() => setImgError(true)}
          zoomScale={2}
          alt="Entry Image"
          containerClass="col-md-6"
          style={{ objectFit: "contain" }} // Set explicit dimensions
        />

        <div className="col-md-6">
          {parkingSessionTable(details)}
        </div>
      </div>
    </Card.Body>
  </Card>;
}