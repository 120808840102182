// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ag-theme-alpine-dark {
  --ag-foreground-color: rgb(255, 255, 255);
  --ag-background-color: rgb(40, 48, 66);
  --ag-header-foreground-color: rgb(255, 255, 255);
  --ag-header-background-color: rgb(40, 48, 66);
  --ag-odd-row-background-color: rgba(0, 0, 0, 0.03);
  --ag-header-column-resize-handle-color: rgb(40, 48, 66);
}

.ag-center-cols-clipper {
  min-height: unset !important;
}

.ag-header-icon.ag-header-cell-menu-button {
  opacity: 1 !important;
}

.ag-root-wrapper {
  border-radius: 5px;
}

.ag-tooltip {
  /* background-color: lightblue;
  border: 1px solid black; */
}

.inactive-row {
  text-decoration: line-through; /* Cross line for the text */
  opacity: 0.5; /* Make the entire row semi-transparent */
  pointer-events: none; /* Disable clicks and hover events */
}

/* Add padding between rows */`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/styledaggrid/style.scss"],"names":[],"mappings":"AAAA;EACE,yCAAA;EACA,sCAAA;EACA,gDAAA;EACA,6CAAA;EACA,kDAAA;EACA,uDAAA;AACF;;AAEA;EACE,4BAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE;4BAAA;AAEF;;AACA;EACE,6BAAA,EAAA,4BAAA;EACA,YAAA,EAAA,yCAAA;EACA,oBAAA,EAAA,oCAAA;AAEF;;AACA,6BAAA","sourcesContent":[".ag-theme-alpine-dark {\n  --ag-foreground-color: rgb(255, 255, 255);\n  --ag-background-color: rgb(40, 48, 66);\n  --ag-header-foreground-color: rgb(255, 255, 255);\n  --ag-header-background-color: rgb(40, 48, 66);\n  --ag-odd-row-background-color: rgba(0, 0, 0, 0.03);\n  --ag-header-column-resize-handle-color: rgb(40, 48, 66);\n}\n\n.ag-center-cols-clipper {\n  min-height: unset !important;\n}\n\n.ag-header-icon.ag-header-cell-menu-button {\n  opacity: 1 !important;\n}\n\n.ag-root-wrapper {\n  border-radius: 5px;\n}\n\n.ag-tooltip {\n  /* background-color: lightblue;\n  border: 1px solid black; */\n}\n.inactive-row {\n  text-decoration: line-through; /* Cross line for the text */\n  opacity: 0.5; /* Make the entire row semi-transparent */\n  pointer-events: none; /* Disable clicks and hover events */\n}\n\n/* Add padding between rows */\n// .ag-row {\n//   padding-bottom: 50px; /* Adjust the padding value as needed */\n// }\n\n// .ag-theme-alpine-dark .ag-row {\n//   padding-bottom: 10px; /* Adjust the padding value as needed */\n//   padding-top: 10px; /* Adjust the padding value as needed */\n// }\n// .ag-theme-alpine-dark .ag-row {\n//   padding-top: 10px;  /* Adjust the padding value as needed */\n//   padding-bottom: 10px;  /* Adjust the padding value as needed */\n//   display: flex;\n//   align-items: center;\n// }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
