import axios from 'axios';
import {
  createAsyncThunk,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import {
  IQueryParams,
  createEntitySlice,
  EntityState,
  serializeAxiosError,
} from 'app/shared/reducers/reducer.utils';
import { IValidation, defaultValue } from 'app/shared/model/validation.model';

const initialState: EntityState<IValidation> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  links: null,
  qrCodes: null,
};

const apiUrl = 'api/validations';

// Actions

export const getEntities = createAsyncThunk(
  'validation/fetch_entity_list',
  async ({ page, size, sort }: IQueryParams) => {
    const requestUrl = `${apiUrl}?${
      sort ? `page=${page}&size=${size}&sort=${sort}&` : ''
    }cacheBuster=${new Date().getTime()}`;
    return axios.get<IValidation[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'validation/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IValidation>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'validation/create_entity',
  async (entity: IValidation, thunkAPI) => {
    console.log("entity2", entity);
    const result = await axios.post<IValidation>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);
export const downloadQR = async (id: string | number) => {
  const requestUrl = `${apiUrl}/${id}/download-qr`;
  return await axios.get(requestUrl, {
    responseType: 'blob', // Ensures binary data is returned
  });
}
export const getQRCodeImageEntity = createAsyncThunk(
  'validation/qr_image_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}/qr-image`;
    return axios.get<IValidation>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const sendEmailEntity = createAsyncThunk(
  'validation/send_email_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}/send-email`;
    return axios.get<IValidation>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'validation/update_entity',
  async (entity: IValidation, thunkAPI) => {
    const result = await axios.put<IValidation>(
      `${apiUrl}/${entity.id}`,
      cleanEntity(entity)
    );
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'validation/partial_update_entity',
  async (entity: IValidation, thunkAPI) => {
    const result = await axios.patch<IValidation>(
      `${apiUrl}/${entity.id}`,
      cleanEntity(entity)
    );
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'validation/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IValidation>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const ValidationSlice = createEntitySlice({
  name: 'validation',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getQRCodeImageEntity), (state, action) => {
        state.loading = false;
        console.log("action.payload", action.payload);
        state.links = action.payload.data;
        state.qrCodes = action.payload.data;
      })
      .addMatcher(isFulfilled(sendEmailEntity), (state, action) => {
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(
        isFulfilled(createEntity, updateEntity, partialUpdateEntity),
        (state, action) => {
          state.updating = false;
          state.loading = false;
          state.updateSuccess = true;
          state.entity = action.payload.data;
        }
      )
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(
        isPending(
          createEntity,
          updateEntity,
          partialUpdateEntity,
          deleteEntity
        ),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      );
  },
});

export const { reset } = ValidationSlice.actions;

// Reducer
export default ValidationSlice.reducer;
