import React, { useMemo } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StyledAGGrid from 'app/components/styledaggrid';
import { StyledGridDataSource } from 'app/components/styledaggrid/StyledGridDataSource';
import {
  createColumnDef,
  createDownloadColumnMapping,
  FilterType,
} from 'app/entities/ColumnDefs';
import { Button } from 'reactstrap';
import { formatDate } from 'app/shared/util/date-utils';
import { Calendar } from 'app/components/calendar/calendar';
import ButtonsContainer from 'app/components/buttonsContainer';
import TitleContainer from 'app/components/titleContainer';
import { twoDecimalFormatter } from 'app/shared/util/numbers';
import DownloadPaymentReceipt from 'app/components/downloadPaymentReceipt';
import {  getColumnDefsForEnvironment } from 'app/config/constants';
import { useConfigContext } from 'app/contexts/ConfigContext';
import { createExitLPRColumnDef, createLPRColumnDef } from 'app/components/lpr-column';

export const Transactions = () => {
  const config = useConfigContext();
  const columnDefsDownloadOption = {
    headerName: 'Download',
    field: 'receiptUrl',
    cellRenderer: DownloadPaymentReceipt,
    sortable: false,
    suppressMovable: false,
    lockVisible: false,
    cellRendererParams: params => ({
      url: params.data?.receiptUrl,
    }),
    suppressMenu: true,
  };
  const columnDefs = [
    createColumnDef('time', 'Date & Time', FilterType.DATE_EXTERNAL, params =>
      formatDate(params.value)
    ),
    createColumnDef('accountId', 'Details', FilterType.EQUALS),
    createColumnDef('paymentAccountId', 'Payment Account Id', FilterType.NONE),
    createColumnDef('id', 'Transaction ID', FilterType.EQUALS),
    createColumnDef(
      'amount',
      'Amount ($)',
      FilterType.EQUALS,
      twoDecimalFormatter
    ),
    createColumnDef(
      'originalFee',
      'Original Fee ($)',
      FilterType.EQUALS,
      twoDecimalFormatter
    ),
    createColumnDef('isFeeChanged', 'Is Fee Changed', FilterType.EQUALS),
    createColumnDef('laneId', 'Zone ID', FilterType.EQUALS),
    createColumnDef('laneId', 'Lane ID', FilterType.EQUALS),
    createColumnDef('cardType', 'Card Type', FilterType.EQUALS),
    createColumnDef('durationInMinutes', 'Duration(minutes)', FilterType.EQUALS),
    createColumnDef('location', 'Location', FilterType.EQUALS),
    createColumnDef('receiptId', 'Receipt', FilterType.NONE),
    // createColumnDef('plate', 'Plate', FilterType.EQUALS),
    createLPRColumnDef(config),
    createExitLPRColumnDef(config),
    // createColumnDef('exitPlate', 'Exit Plate', FilterType.EQUALS),
    createColumnDef('bayId', 'Bay ID', FilterType.NONE),
    columnDefsDownloadOption,
  ];



  const columnDefForEnvironment = getColumnDefsForEnvironment(columnDefs, config.PAYMENT_HISTORY_COLUMNS);

  const dataSource = useMemo(() => {
    return new StyledGridDataSource(
      'id,desc',
      'api/transactions',
      false,
      columnDefForEnvironment
    );
  }, []);
  const handleDownload = () => {
    dataSource.downloadAll(createDownloadColumnMapping(columnDefForEnvironment));
  };
  const handleDateFilter = range => {
    dataSource.handleDateFilter('time', range);
  };
  function downloadButton(): React.ReactNode {
    return (
      <Button
        variant="success"
        className="me-1 mb-1 btn btn-success"
        onClick={handleDownload}
        style={{ margin: '0px 0px 2px 0px' }}
      >
        <FontAwesomeIcon
          icon="download"
          style={{ marginRight: '5px', width: '10px', height: '10px' }}
        />
        Download
      </Button>
    );
  }

  return (
    <React.Fragment>
      <div>
        <TitleContainer>
          <div>
            <h3>Payment History</h3>
          </div>

          <ButtonsContainer>
            <Button
              variant="primary"
              onClick={() => dataSource.refresh()}
              className="me-1 mb-1 btn btn-info"
            >
              <FontAwesomeIcon
                icon="sync"
                style={{ marginRight: '5px', width: '12px', height: '12px' }}
              />
              Refresh
            </Button>
            {downloadButton()}
            <div style={{ marginTop: '0px' }} className="mb-1">
              <Calendar applyDateFilter={range => handleDateFilter(range)} />
            </div>
          </ButtonsContainer>
        </TitleContainer>
        <StyledAGGrid
          columnDefs={columnDefForEnvironment}
          fullWidth={true}
          noflex={true}
          paginated={true}
          paginationPageSizeNumber={10}
          dataSource={dataSource}
          rowModelType={'infinite'}
        />
      </div>
    </React.Fragment>
  );
};

export default Transactions;
