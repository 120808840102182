export const getLPRImage = (basePath:String, sessionId: string) => {
    return `${basePath}/${getFolder(sessionId)}/lpr-${sessionId}.jpg`;
}

export const getEnterImage = (basePath:String, sessionId: string) => {
    return `${basePath}/${getFolder(sessionId)}/enter-${sessionId}.jpg`;
}

export const getExitImage = (basePath:String, sessionId: string) => {
    return `${basePath}/${getFolder(sessionId)}/exit-${sessionId}.jpg`;
}
export const getSessionImage = (basePath:String, sessionId: string) => {
    return `${basePath}/${getFolder(sessionId)}/event-${sessionId}.jpg`;
}


export const getFallbackImage = (basePath:String) => {
    return `${basePath}/no-image.svg`;
}

export const getFallbackImageJpg = (basePath:String) => {
    return `${basePath}/no-image.jpg`;
}

const getFolder = (sessionId: string) => {
    // create  ./images/YYYY/MM/DD/HH/enter-${sessionid}.jpg 
    // from epoch ms session id
    const date = new Date(parseInt(sessionId));
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const path = `${year}/${month}/${day}/${hour}`;
    console.log(`path: ${path} ${sessionId}`);
    return path;
    
}