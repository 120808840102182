import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ShowLPRModal from 'app/components/StyledModal/show-lpr';
import ButtonsContainer from 'app/components/buttonsContainer';
import { Calendar } from 'app/components/calendar/calendar';
import StatusBadge from 'app/components/statusBadge';
import StyledAGGrid from 'app/components/styledaggrid';
import { StyledGridDataSource } from 'app/components/styledaggrid/StyledGridDataSource';
import TitleContainer from 'app/components/titleContainer';
import { getColumnDefsForEnvironment } from 'app/config/constants';
import { useConfigContext } from 'app/contexts/ConfigContext';
import {
  FilterType,
  createColumnDef,
  createDownloadColumnMapping,
} from 'app/entities/ColumnDefs';
import { IValidation } from 'app/shared/model/validation.model';
import { formatDate } from 'app/shared/util/date-utils';
import {
  twoDecimalFormatter,
  twoDecimalFormatterDevideBy100,
} from 'app/shared/util/numbers';
import axios from 'axios'; // Import axios for making HTTP requests
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import '../home/style.css';
import { getCurrentStatus } from '../validation/validation-status';
import ActionButtons, { StatusButton } from 'app/components/actionButtons';
import { getFallbackImage, getLPRImage } from 'app/session-images';
import { createExitLPRColumnDef, createLPRColumnDef } from 'app/components/lpr-column';
import '../home/style.css';

export const Parking = () => {
  const config = useConfigContext();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const [validation, setValidation] = useState<IValidation | null>(null);
  const [selectedSession, setSelectedSession] = useState(null);

  // API call to fetch validation data
  useEffect(() => {
    if (queryParams['validationId.equals']) {
      const fetchValidation = async () => {
        try {
          const response = await axios.get(`/api/validations/${queryParams['validationId.equals']}`);
          setValidation(response.data); // Assuming the response has a name field
        } catch (error) {
          console.error('Failed to fetch validation', error);
          setValidation(null);
        }
      };

      fetchValidation();
    }
  }, []);
  

  const allColumnDefs = [
    createLPRColumnDef(config),
    createExitLPRColumnDef(config),
    {
      field: 'Actions',
      headerName: 'Details',
      filter: false,
      sortable: false,
      width: 90,
      cellRenderer: ActionButtons,
      cellRendererParams: params => {
        return {
          titles: ['View'],
          styles: ['btn-success'],
          icons: ['eye'],
          onClicks: [
            (event)=>{
              event.preventDefault();
              handleOpenLPRModal(params.data);
            }
          ]
        }
      },
    },
    // columnDefsLaneStatus,
    // createColumnDef('sessionId', 'Details', FilterType.NONE,
    //   undefined,
    //   params => {
    //     return (
    //       <a href="#" onClick={(event) => {
    //           event.preventDefault();
    //           handleOpenLPRModal(params.data);
    //         }}>
    //         Details
    //       </a>
    //     );
    //   }
    // ),   
    createColumnDef(
      'entryTime',
      'Entry Time',
      FilterType.DATE_EXTERNAL,
      params => formatDate(params.value)
    ),
    createColumnDef('exitTime', 'Exit Time', FilterType.NONE, params =>
      formatDate(params.value)
    ),
    createColumnDef('paymentType', 'Payment Type', FilterType.NONE),
    createColumnDef('accountId', 'Account Id', FilterType.EQUALS),
    createColumnDef('validationId', 'Validation Id', FilterType.EQUALS),
    {
      ...createColumnDef('batchId', 'Batch Id', FilterType.EQUALS),
      cellRenderer: (params) => {
        const url = `/pages/parking?validationId.equals=${params?.data?.validationId}`;
        const goToValidationEvents = () => () => {
          const url = `/pages/parking?validationId.equals=${params?.data?.validationId}`;

          window.open(url, '_blank');
        };
        return <a href='/pages/parking' onClick={goToValidationEvents()}>{params.value}</a>;
      }
    },
    createColumnDef('validationCounter', 'Validation Counter', FilterType.NONE),
    createColumnDef('paymentAccountId', 'Payment Account Id', FilterType.NONE),
    createColumnDef('entryLaneId', 'Zone', FilterType.EQUALS),
    createColumnDef('entryLaneId', 'Lane Id', FilterType.EQUALS),
    createColumnDef('bayId', 'Bay ID', FilterType.EQUALS),
    // createColumnDef('plate', 'LPR', FilterType.EQUALS,
    //   undefined,
    //   params => {
    //     return (
    //       <a href="#" onClick={(event) => {
    //           event.preventDefault();
    //           handleOpenLPRModal(params.data);
    //         }}>
    //         {params.data?.plate}
    //       </a>
    //     );
    //   }
    // ),          
    createColumnDef(
      'durationInMinutes',
      'Duration (Minutes)',
      FilterType.EQUALS
    ),
    createColumnDef(
      'fee',
      'Fee ($)',
      FilterType.NONE,
      twoDecimalFormatterDevideBy100
    ),
    createColumnDef('paid', 'Paid ($)', FilterType.NONE, twoDecimalFormatter),
  ];
  const columnDefs = getColumnDefsForEnvironment(allColumnDefs, config.PARKING_EVENTS_COLUMNS)
    .filter(colDef => {
      if (colDef.field === 'batchId') {
        return !validation;
      }
      return true;
    });
  const apiUrlWithParams = useMemo(() => {
    const baseUrl = 'api/parking-sessions';
    const queryStringified = queryString.stringify(queryParams);
    return queryStringified ? `${baseUrl}?${queryStringified}` : baseUrl;
  }, [queryParams]);

  const dataSource = useMemo(() => {
    return new StyledGridDataSource(
      'entryTime,desc',
      apiUrlWithParams,
      false,
      columnDefs
    );
  }, []);
  const handleDownload = () => {
    dataSource.downloadAll(createDownloadColumnMapping(columnDefs));
  };
  const handleDateFilter = range => {
    dataSource.handleDateFilter('entryTime', range);
  };
  const handleOpenLPRModal = (session) => {
    setSelectedSession(session);
  };

  const handleClose = () => {
    setSelectedSession(null);
  };
  function downloadButton(): React.ReactNode {
    return (
      <Button
        variant="success"
        className="me-1 mb-1 btn btn-success"
        onClick={handleDownload}
        style={{ margin: '0px 0px 2px 0px' }}
      >
        <FontAwesomeIcon
          icon="download"
          style={{ marginRight: '5px', width: '10px', height: '10px' }}
        />
        Download
      </Button>
    );
  }
  return (
    <React.Fragment>
      <div>
        <TitleContainer>
          <div style={{ minWidth: '200px' }}>
            <h3>{`Parking Events`}</h3>
          </div>

          <ButtonsContainer>
            <Button
              variant="primary"
              onClick={() => dataSource.refresh()}
              className="me-1 mb-1 btn btn-info"
            >
              <FontAwesomeIcon
                icon="sync"
                style={{ marginRight: '5px', width: '12px', height: '12px' }}
              />
              Refresh
            </Button>
            {downloadButton()}
            <div style={{ marginTop: '0px' }} className="mb-1">
              <Calendar applyDateFilter={range => handleDateFilter(range)} />
            </div>
          </ButtonsContainer>
        </TitleContainer>
        {validation &&

          <Card className="flex-fill w-100">
            <Card.Header style={{ marginBottom: '-40px' }}>
              <Card.Title> Validation Details</Card.Title>
            </Card.Header>
            <Card.Body>
              <StyledAGGrid
                rowData={[validation]}
                columnDefs={[
                  {
                    field: 'Status',
                    headerName: 'Status',
                    cellRenderer: StatusBadge,
                    sortable: false,
                    filter: false,
                    cellRendererParams: params => {
                      const startTime = params?.data?.startTime;

                      const endTime = params?.data?.endTime;

                      if (!startTime || !endTime) {
                        return { type: 'default', text: 'Invalid Time' };
                      }

                      return getCurrentStatus(startTime, endTime);
                    },
                  },
                  createColumnDef('email', 'Email', FilterType.NONE),
                  createColumnDef('description', 'Description', FilterType.NONE),
                  createColumnDef('startTime', 'Start Time', FilterType.NONE, params => formatDate(params.value)),
                  createColumnDef('endTime', 'End Time', FilterType.NONE, params => formatDate(params.value)),
                  createColumnDef('batchId', 'Batch ID', FilterType.NONE),
                  createColumnDef('count', 'Issued', FilterType.NONE),
                  createColumnDef('multipleEntries', 'Usage Type', FilterType.NONE, p => p.value ? 'M' : 'S'),
                  createColumnDef('rateSchedule.name', 'Type', FilterType.NONE),
                ]}
                sortable={false}
                filter={false}
                resizable={false}
                showRefreshButton={false}
              />
            </Card.Body>
          </Card>
        }
        <StyledAGGrid
          columnDefs={columnDefs}
          fullWidth={true}
          noflex={true}
          paginated={true}
          paginationPageSizeNumber={10}
          dataSource={dataSource}
          rowModelType={'infinite'}
        />
        <ShowLPRModal
          showModal={selectedSession !== null}
          sessionId={selectedSession?.sessionId}
          cancelMethod={handleClose}
          // confirmMethod={downloadQRCode}
          titleText="LPR"
          confirmButtonText="Download"
          confirmButtonStyle="btn-success"
        />
      </div>
    </React.Fragment>
  );
};
interface LPRDetailsProps {
  session: {
    sessionId: string;
    plate: string;
    entryTime: string;
    exitTime: string;
    durationInMinutes: number;
    fee: number;
    paid: number;
  };
}


export default Parking;
