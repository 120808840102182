import React from 'react';
import { Button, Col, Dropdown, Row } from 'react-bootstrap';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { set } from 'lodash';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { IRateSchedule } from 'app/shared/model/rate-schedule.model';

interface ActionButtonsProps {
  titles: string[];
  icons?: any[];
  styles?: string[];
  onClicks: (() => void)[];
}

interface Title {
  overallStatus: string;
  laneId: string;
}
interface StatusButtonsProps {
  titles: Title[];
  icons?: any[];
  styles?: string[];
  onClicks: (() => void)[];
}

export default function ActionButtons(props: ActionButtonsProps) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        justifyItems: 'center',
        flexDirection: 'row',
        height: '100%',
      }}
    >
      {props.titles?.map((title, index) => (
        <Button
          key={index}
          className={`btn-pill me-1 btn btn-sm ${props?.styles[index]}`}
          onClick={props?.onClicks[index]}
        >
          {props?.icons && props?.icons[index] && (
            <FontAwesomeIcon
              icon={props?.icons[index]}
              style={{ marginRight: '5px', width: '10px', height: '10px' }}
            />
          )}
          {title}
        </Button>
      ))}
    </div>
  );
}

export function StatusButton(props: StatusButtonsProps) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        justifyItems: 'center',
        flexDirection: 'row',
        height: '100%',
        minWidth: '100px !important',
      }}
    >
      {props.titles?.map((title, index) => (
        <button
          key={index}
          className={`d-flex flex-row gap-2 btn-pill me-1 btn btn-sm ${props?.styles[index]}`}
          style={{ cursor: 'pointer', width: 'fit-content' }}
          onClick={props?.onClicks[index]}
        >
          <p style={{ margin: '0px' }}>{title?.laneId}</p>

          {title?.overallStatus === 'ERROR' && (
            <img src="content/assets/img/cloud-off-white.svg" width="20px" />
          )}
          {title?.overallStatus === 'OK' && (
            <img src="content/assets/img/activity-white.svg" width="20px" />
          )}
          {title?.overallStatus === 'WARN' && (
            <img
              src="content/assets/img/alert-triangle-white.svg"
              width="20px"
            />
          )}
        </button>
      ))}
    </div>
  );
}
