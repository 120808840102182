import { getFallbackImage, getLPRImage } from "app/session-images";
import React from "react";

export const lprCellRenderer = (config, params) => {
    const imageUrl = getLPRImage(config.LPR_IMAGES_BASE_URL, params?.data?.sessionId);
    const fallbackImgUrl = getFallbackImage(config.LPR_IMAGES_BASE_URL);

    return (
        // <a href="#" onClick={(event) => {
        //   event.preventDefault();
        //   handleOpenLPRModal(params.data, params.data);
        // }}>
        <img
            src={imageUrl}
            alt={fallbackImgUrl}
            style={{
                maxWidth: '150px',
                maxHeight: '30px',
                width: 'auto',
                height: 'auto',
                objectFit: 'contain',
            }}
            onError={(e) => {
                (e.target as HTMLImageElement).src = fallbackImgUrl;
            }}
        />
        // </a>

    );
};

export const createLPRColumnDef = (config) => {
    return {
        field: 'plate',
        headerName: 'LPR',
        sortable: false,
        suppressMovable: true,
        lockVisible: true,
        tooltipField: null,
        tooltipComponent: null,
        valueFormatter: params => {
            return params.data?.plate;
        },
        minWidth: 155,
        cellRenderer: p=>lprCellRenderer(config, p),
        filterParams: {
            filterOptions: ['equals'],
            defaultOption: 'equals',
            suppressAndOrCondition: true,
        },
        filter: true,
        tooltipValueGetter: () => null
    }
};

export const createExitLPRColumnDef = (config) => {
    return {
        field: 'exitPlate',
        headerName: 'Exit LPR',
        sortable: false,
        suppressMovable: true,
        lockVisible: true,
        tooltipField: null,
        tooltipComponent: null,
        valueFormatter: params => {
            return params.data?.plate;
        },
        minWidth: 155,
        cellRenderer: p=>lprCellRenderer(config, p),
        filterParams: {
            filterOptions: ['equals'],
            defaultOption: 'equals',
            suppressAndOrCondition: true,
        },
        filter: true,
        tooltipValueGetter: () => null
    }
};