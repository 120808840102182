import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { toast } from 'react-toastify';

import {
  getEvents, updateOccupancy
} from 'app/entities/event/event.reducer';

import { getGates } from 'app/entities/gate/gate.reducer';
import { getLocations } from 'app/entities/location/location.reducer';
import { getParkingSessions_ } from '../../entities/parking-session/parking-session.reducer';
import { getDailyFinacials } from '../../entities/transaction/daily-finacials.reducer';

import ActionButtons, { StatusButton } from 'app/components/actionButtons';
import StyledAGGrid from 'app/components/styledaggrid';
import AddDataModal from 'app/components/StyledModal/add-data';
import { createEntity as createEntity } from 'app/entities/event/event.reducer';
import { closeEventEntity as closeEventEntity } from 'app/entities/event/event.reducer';
import { updateEntity as updateGateEntity } from 'app/entities/gate/gate.reducer';

import BarChart from 'app/components/BarChart';
import PieChart from 'app/components/PieChart';
import Speedometer, { SpeedometerData } from 'app/components/Speedometer';
import CreateEventModal from 'app/components/StyledModal/create-event';
import EditOccupanyModel from 'app/components/StyledModal/edit-occupancy';
import GateControlModel from 'app/components/StyledModal/gate-control-reason';
import LaneStatusModel from 'app/components/StyledModal/lane-status';
import { createColumnDef, FilterType } from 'app/entities/ColumnDefs';
import { resetHardware } from 'app/entities/lane/lane.reducer';
import usePalette from 'app/hooks/usePalette';
import { EventType } from 'app/shared/model/enumerations/event-type.model';
import { GateStatus } from 'app/shared/model/enumerations/gate-status.model';
import { IEvent } from 'app/shared/model/event.model';
import { IGate } from 'app/shared/model/gate.model';
import { ILaneStatus } from 'app/shared/model/lane-status.model';
import { ILocation } from 'app/shared/model/location.model';
import { IParkingSession } from 'app/shared/model/parking-session.model';
import { IDailyFinancials } from 'app/shared/model/transaction.model';
import { formatDate } from 'app/shared/util/date-utils';
import { twoDecimalFormatter } from 'app/shared/util/numbers';
import axios from 'axios';
import _, { filter } from 'lodash';
import { Card } from 'react-bootstrap';

import ModifyFee from 'app/components/modifyFee';
import './style.css';
import { getColumnDefsForEnvironment } from 'app/config/constants';
import { useConfigContext } from 'app/contexts/ConfigContext';
import ShowLPRMoadal from 'app/components/StyledModal/show-lpr';
import { fi } from 'date-fns/locale';
import { getFallbackImage, getLPRImage } from 'app/session-images';
import { createLPRColumnDef } from 'app/components/lpr-column';
export const Home = () => {
  const config = useConfigContext();
  const dispatch = useAppDispatch();

  interface FinancialStatistics {
    totalPaidCurrentMonth: number;
    totalPaidLastMonth: number;
    totalPaidCurrentWeek: number;
    totalPaidLastWeek: number;
    totalPaidCurrentYear: number;
    totalPaidLastYear: number;
    averageTransaction: number;
    totalPaidToday: number;
  }
  const defaultStatistics: FinancialStatistics = {
    totalPaidCurrentMonth: 0,
    totalPaidLastMonth: 0,
    totalPaidCurrentWeek: 0,
    totalPaidLastWeek: 0,
    totalPaidCurrentYear: 0,
    totalPaidLastYear: 0,
    averageTransaction: 0,
    totalPaidToday: 0,
  };

  const defaultLanesData: LanesData = {
    dailyFinancials: [],
    events: [],
    parkingSessions: [],
    gates: [],
    locations: [],
    currentOccupancy: 0,
    financialStats: defaultStatistics,
  };
  interface LanesData {
    dailyFinancials: IDailyFinancials[];
    events: IEvent[];
    parkingSessions: IParkingSession[];
    gates: IGate[];
    locations: ILocation[];
    currentOccupancy: number;
    financialStats: FinancialStatistics;
  }

  const [lanesData, setLanesData] = useState<LanesData>(defaultLanesData);
  const [showDataModal, setShowDataModal] = useState(false);
  const [showLaneStatusModal, setShowLaneStatusModal] = useState(false);
  const [currentLaneData, setCurrentLaneData] = useState(null);
  const [showOpenSessionData, setShowOpenSessionData] = useState(false);
  const [currentGateData, setCurrentGateData] = useState(null);
  const [showCreateEventModal, setShowCreateEventModal] = useState(false);
  const [showGateControlModal, setGateControlModal] = useState(false);
  const [showEditOccupancyModal, setEditOccupancyModal] = useState(false);
  const [gateControlData, setgateControlData] = useState({});
  const [gateControlType, setgateControlType] = useState({});
  const [modifiedEventData, setModifiedEventData] = useState(null);
  const transactionList: IDailyFinancials[] = lanesData.dailyFinancials;
  const currentOccupancy = occupancy(lanesData.currentOccupancy);
  const financialStatistics = lanesData.financialStats;
  const eventList: IEvent[] = lanesData.events;
  const gateList: IGate[] = lanesData.gates;
  const parkingSessions: IParkingSession[] = lanesData.parkingSessions;
  const account = useAppSelector(state => state.authentication.account);
  const [selectedSession, setSelectedSession] = useState(null);

  const locations = lanesData.locations;
  const [stageAndHybrid, setStageAndHybrid] = useState('');
  const [businessDetails, setBusinessDetails] = useState('');



  useEffect(() => {

    axios.get(`api/profile-info`).then(r => {
      setStageAndHybrid(r.data);
    });

    axios.get(`api/business-details`).then(r => {
      setBusinessDetails(r?.data?.[0]?.siteName ?? '');
    });

  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          dailyFinancials,
          events,
          parkingSessions,
          gates,
          locationData,
          currentOccupancy,
          financialStats,
        ] = await Promise.all([
          getDailyFinacials(0, 7, `date,desc`),
          getEvents(null, null, `time,desc`),
          getParkingSessions_(`paid.specified=true`, 0, 100, `receiptId,desc`),
          getGates(null, null, `id,asc`),
          getLocations(null, null, `id,asc`),
          axios.get<number>(`api/parking-sessions-count?exitTime.specified=false`),
          axios.get(`api/financialStatistics`),
        ]);

        const newData = {
          dailyFinancials: dailyFinancials.data,
          events: events.data,
          parkingSessions: parkingSessions.data,
          gates: gates.data,
          locations: locationData.data,
          currentOccupancy: currentOccupancy.data,
          financialStats: financialStats.data,
        };

        if (!_.isEqual(lanesData, newData)) {
          setLanesData(newData);
        }
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    // Call fetchData initially
    fetchData();

    // Set an interval to call fetchData every 2 seconds
    const intervalId = setInterval(() => {
      fetchData();
    }, 2000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [lanesData]);



  const findGateByLaneId = (gates: any[], laneId: string) => {
    return gates.find(gate => gate.laneId === laneId);
  };

  const latestTimeForEachLane = eventList;
  const mergedEventAndGateDataUnsorted = latestTimeForEachLane.map(
    eventItem => {
      const matchingGate = gateList.find(
        gateItem => gateItem.laneId === eventItem.laneId
      );

      return {
        ...eventItem,
        ...matchingGate,
        laneStatus: matchingGate?.laneStatus,
      };
    }
  );
  const mergedEventAndGateData = _.sortBy(
    mergedEventAndGateDataUnsorted,
    'laneId'
  );

  const createNewEvent = (data: IEvent) => {
    setModifiedEventData(data);
    setShowDataModal(true);
  };

  const handleCloseEditOccupancyModal = () => {
    setEditOccupancyModal(false);
  };
  const handleSubmitEditOccupancyModal = occupancy => {
    updateOccupancy(occupancy);
    setEditOccupancyModal(false);
  };

  const handleCloseModal = () => {
    setShowDataModal(false);
  };

  const handleOpenLPRModal = (session, event: IEvent) => {
    setSelectedSession(session);
    setCurrentLaneData(event);
  };

  const handleCloseLprModal = () => {
    setSelectedSession(null);
  };
  const handleCloseLaneStatusModel = () => {
    setCurrentLaneData(null);
    setCurrentGateData(null);
    setShowLaneStatusModal(false);
  };

  const handleOpenCreateEventStatusModal = () => {
    setShowCreateEventModal(true);
    setShowLaneStatusModal(false);
  };
  const handleCloseOpenEvent = () => {
    setShowCreateEventModal(true);
    setShowLaneStatusModal(false);
    setShowOpenSessionData(true);
  };
  const handleCloseCreateEventModal = () => {
    setCurrentLaneData(null);
    setCurrentGateData(null);
    setShowCreateEventModal(false);
    setShowOpenSessionData(false);
  };
  const handleSubmitCreateEventModal = createEventData => {
    const isCloseEvent = showOpenSessionData;
    setCurrentLaneData(null);
    setCurrentGateData(null);
    setShowOpenSessionData(false);
    if (isCloseEvent) {
      dispatch(closeEventEntity(createEventData))
    } else {
      dispatch(createEntity(createEventData));
    }
  };

  const handleSubmitModal = (feeChanged, reason) => {
    const { id, ...updatedObject } = modifiedEventData;
    updatedObject.fee = feeChanged;
    updatedObject.reason = reason;
    updatedObject.eventType = EventType.FEE_CHANGED;
    updatedObject.time = new Date().toISOString();
    updatedObject.performedById = account.id;
    dispatch(createEntity(updatedObject));
  };

  const actionColumnsForEvents = {
    headerName: 'Fee ($)',
    minWidth: 125,
    cellRenderer: function (params) {
      if (params.data.fee === undefined || params.data.fee === null) {
        return null;
      }
      return ModifyFee(params);
    },
    sortable: false,
    suppressMovable: true,
    lockVisible: true,
    filter: false,
    cellRendererParams: params => ({
      title: params.data.fee,
      onClick: () => createNewEvent(params.data),
      icon: 'pencil-alt',
    }),
  };

  const actionColumnsForGateControl = {
    headerName: 'Gate Controls',
    minWidth: 250,
    cellRenderer: ActionButtons,
    sortable: false,
    suppressMovable: true,
    lockVisible: true,
    filter: false,
    cellRendererParams: params => ({
      titles: [
        'Open',
        'Close',
        params.data.lockStatus === 'LOCKED' ? 'Release' : 'Hold-Open',
      ],
      onClicks: [
        openGateStatus(params.data, params.data.fee),
        closeGateStatus(params.data, params.data.fee),
        params.data.lockStatus === 'LOCKED'
          ? unlockGateStatus(params.data, params.data.fee)
          : lockGateStatus(params.data, params.data.fee),
      ],
      styles: ['btn-success', 'btn-success', params.data.lockStatus === 'LOCKED' ? 'btn-danger' : 'btn-success'],
    }),
  };

  const columnDefsGateStatus = {
    headerName: 'Gate Status',
    field: 'status',
    sortable: false,
    suppressMovable: true,
    lockVisible: true,
    filter: false,
  };

  const openLaneStatus = (data: IEvent, laneStatus: ILaneStatus) => () => {
    setCurrentLaneData(data);
    setCurrentGateData(laneStatus);
    setShowLaneStatusModal(true);
  };
  const lprCellRenderer = (params) => {
    const imageUrl = getLPRImage(config.LPR_IMAGES_BASE_URL, params?.data?.sessionId);
    const fallbackImgUrl =  getFallbackImage(config.LPR_IMAGES_BASE_URL);

    return (
      // <a href="#" onClick={(event) => {
      //   event.preventDefault();
      //   handleOpenLPRModal(params.data, params.data);
      // }}>
      <img
        src={imageUrl}
        alt={fallbackImgUrl}
        style={{
          maxWidth: '150px',
          maxHeight: '30px',
          width: 'auto',
          height: 'auto',
          objectFit: 'contain',
        }}
        onError={(e) => {
          (e.target as HTMLImageElement).src = fallbackImgUrl;
        }}
      />
      // </a>

    );
  };

  const columnDefsLaneStatus = {
    headerName: 'Lane ID',
    minWidth: 100,
    cellRenderer: StatusButton,
    sortable: false,
    suppressMovable: true,
    lockVisible: true,
    cellRendererParams: params => ({
      titles: [params.data?.laneStatus],
      onClicks: [openLaneStatus(params?.data, params.data?.laneStatus)],
      styles: [getStatusStyle(params.data?.laneStatus?.overallStatus)],
    }),
    filter: false
  };

  function getStatusStyle(overallStatus) {
    switch (overallStatus) {
      case 'OK':
        return 'btn-success fixed-width-btn';
      case 'ERROR':
        return 'btn-danger fixed-width-btn';
      case 'WARN':
        return 'btn-warning fixed-width-btn';
      // Add more cases as needed
      default:
        return 'btn-secondary fixed-width-btn'; // default style
    }
  }


  const homeColumnDefsforEvents = [
    // createColumnDef('paymentMethodId', 'Payment Method', FilterType.NONE),
    createColumnDef('accountId', 'Details', FilterType.NONE),

    {
      field: 'time',
      headerName: 'Event Detail',
      sortable: false,
      suppressMovable: true,
      lockVisible: true,
      valueFormatter: params => {
        return formatDate(params.value);
      },
      minWidth: 155,
      filter: false
    },
  ];
  const lprColumnDef = {
    field: 'plate',
    headerName: 'LPR',
    sortable: false,
    suppressMovable: true,
    lockVisible: true,
    tooltipField: null,
    tooltipComponent: null,
    valueFormatter: params => {
      return params.data?.plate;
    },
    minWidth: 155,
    cellRenderer: lprCellRenderer,
    filterParams: {
      filterOptions: ['equals'],
      defaultOption: 'equals',
      suppressAndOrCondition: true,
    },
    filter: true,
    tooltipValueGetter: () => null, 
  };
  if (stageAndHybrid === 'HYBRID') {
    homeColumnDefsforEvents.push(
      createLPRColumnDef(config)
    );
  }

  const homeColumnDefsforTransactions = getColumnDefsForEnvironment([
    createColumnDef('accountId', 'Details', FilterType.NONE),
    createColumnDef('paid', 'Fee ($)', FilterType.NONE, twoDecimalFormatter),
    createColumnDef('entryTime', 'Entry Time', FilterType.NONE, params =>
      formatDate(params.value)
    ),
    createColumnDef('exitTime', 'Exit Time', FilterType.NONE, params =>
      formatDate(params.value)
    ),
    createColumnDef('entryTime', 'Start Time', FilterType.NONE, params =>
      formatDate(params.value)
    ),
    createColumnDef('exitTime', 'End Time', FilterType.NONE, params => {

      return formatDate(params.value)
    }),
    createColumnDef('durationInMinutes', 'Duration (minutes)', FilterType.NONE),
    createColumnDef('receiptId', 'Receipt', FilterType.NONE),
    // createColumnDef('plate', 'LPR', FilterType.NONE, undefined,
    //   params => {
    //     return (
    //       <a href="#" onClick={(event) => {
    //         event.preventDefault();
    //         handleOpenLPRModal(params.data, params.data);
    //       }}>
    //         {params.data?.plate}
    //       </a>
    //     );
    //   })
      createLPRColumnDef(config)
  ], config.DASHBOARD_TRANSACTIONS_COLUMNS);

  const homeNewColumnDefsforLanes = [
    columnDefsLaneStatus,
    ...homeColumnDefsforEvents,
    actionColumnsForEvents,
    actionColumnsForGateControl,
    columnDefsGateStatus,
  ];

  const gateStatusClose = () => {
    setgateControlData({});
    setgateControlType('');
    setGateControlModal(false);
  };
  const gateStatusSubmit = DataWithReason => {
    setgateControlData({});
    if (gateControlType == 'open') {
      changeGateStatus(DataWithReason, GateStatus.OPEN);
    }
    if (gateControlType == 'close') {
      changeGateStatus(DataWithReason, GateStatus.CLOSE);
    }
    if (gateControlType == 'lock') {
      changeGateStatus(DataWithReason, GateStatus.LOCK);
    }
    if (gateControlType == 'unlock') {
      changeGateStatus(DataWithReason, GateStatus.UNLOCK);
    }
    setgateControlType('');
  };

  const openGateStatus = (data: IGate, fee: number) => () => {
    setgateControlData(data);
    setGateControlModal(true);
    setgateControlType('open');
  };
  const closeGateStatus = (data: IGate, fee: number) => () => {
    setgateControlData(data);
    setGateControlModal(true);
    setgateControlType('close');
  };
  const lockGateStatus = (data: IGate, fee: number) => () => {
    setgateControlData(data);
    setGateControlModal(true);
    setgateControlType('lock');
  };
  const unlockGateStatus = (data: IGate, fee: number) => () => {
    setgateControlData(data);
    setGateControlModal(true);
    setgateControlType('unlock');
  };

  const changeGateStatus = (data: IEvent, gateStatus: GateStatus) => {
    const gate = gateList.find(gate => gate.laneId === data.laneId);
    const { ...gateToUpdate } = gate;
    if (gateToUpdate) {
      gateToUpdate.status = gateStatus;

      dispatch(
        updateGateEntity({
          ...gateToUpdate,
          sessionId: data.sessionId,
          reason: data.reason,
        })
      );
    } else {
      console.log('Gate with specified laneId not found.');
    }
  };

  const palette = usePalette();

  const speedometerData: SpeedometerData[] = [
    {
      label: 'Occupied',
      value: currentOccupancy,
      color: palette.warning,
      className: 'text-warning',
    },
    {
      label: 'Vacant',
      value: emptyOccupancy(currentOccupancy, locations),
      color: palette.danger,
      className: 'text-danger',
    },
    // {
    //   label: 'Total',
    //   value: totalOccupancy(locations),
    //   color: palette.danger,
    //   className: 'text-danger',
    // },
  ];
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  const filteredTransactions = transactionList.filter(transaction => {
    const transactionDate = new Date(transaction.date);
    return transactionDate >= sevenDaysAgo;
  });
  const recentTransactions = _.sortBy(filteredTransactions, transaction => {
    return new Date(transaction.date);
  }).splice(0, 7);

  const transactionsPerDay = _.groupBy(recentTransactions, transaction => {
    return transaction.date;
  });

  const keys = Object.keys(transactionsPerDay);
  const lastSevenTransaction = Object.fromEntries(
    keys.map(key => [key, transactionsPerDay[key]])
  );

  const sumPerDay = _.mapValues(lastSevenTransaction, transactions => {
    return _.sumBy(transactions, 'totalPaid');
  });

  const barChartData = _.map(sumPerDay, (value, label) => {
    return { label, value };
  });
  const adjustedSpeedometerData = speedometerData.map(data => {
    if (data.label === 'Occupied') {
      return { ...data, value: Math.max(0, data.value) };  // Ensure 'Occupied' value is non-negative
    } else if (data.label === 'Vacant') {
      // If 'Vacant' also depends on 'currentOccupancy', we adjust it similarly
      return { ...data, value: emptyOccupancy(Math.max(0, currentOccupancy), locations) };
    }
    return data;
  });
  return (
    <React.Fragment>
      <div
        className="d-none d-sm-block col-auto"
        style={{ marginBottom: '30px' }}
      >
        <h3>Dashboard {businessDetails}</h3>
      </div>
      <div>
        <div className="row" style={{ marginTop: '30px' }}>
          <div className="d-flex col-xl col-md-6">
            <div
              className="flex-fill card"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div className="py-4 card-title">Occupancy (%)</div>
              <div
                className="py-4 card-body"
                style={{ marginTop: '-40px', marginBottom: '-30px' }}
              >
                <Speedometer data={adjustedSpeedometerData} />
              </div>
            </div>
          </div>

          <div className="d-flex col-xl col-md-6">
            <div
              className="flex-fill card"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div className="py-4 card-title">Today's Revenue</div>
              <div className="py-4 card-body" style={{ fontSize: '42px' }}>
                ${financialStatistics.totalPaidToday}
              </div>
            </div>
          </div>

          <div className="d-flex col-xl col-md-6">
            <div
              className="flex-fill card"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div className="py-4 card-title">Week-To-Date Revenue</div>
              <div className="py-4 card-body" style={{ fontSize: '42px' }}>
                ${financialStatistics.totalPaidCurrentWeek}
              </div>
            </div>
          </div>

          <div className="d-flex col-xl col-md-6">
            <div
              className="flex-fill card"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div className="py-4 card-title">Month-To-Date Revenue</div>
              <div className="py-4 card-body" style={{ fontSize: '42px' }}>
                ${financialStatistics.totalPaidCurrentMonth}
              </div>
            </div>
          </div>
        </div>
        {config.DASHBOARD_SHOW_LANES && <div className="d-flex flex-column" style={{ gap: '16px' }}>
          <Card className="flex-fill w-100">
            <Card.Header style={{ marginBottom: '-40px' }}>
              <Card.Title> Lanes</Card.Title>
            </Card.Header>
            <Card.Body>
              <StyledAGGrid
                rowData={mergedEventAndGateData}
                columnDefs={homeNewColumnDefsforLanes}
                sortable={false}
                // filter={false}
                resizable={false}
                showRefreshButton={false}
              />
            </Card.Body>
          </Card>
        </div>
        }
        {showDataModal && (
          <AddDataModal
            show={showDataModal}
            onClose={handleCloseModal}
            onSubmit={handleSubmitModal}
            currentValue={modifiedEventData?.fee}
            surchargePct={getSurchargePct(locations)}
          />
        )}
        {<ShowLPRMoadal
          showModal={selectedSession !== null}
          sessionId={selectedSession?.sessionId}
          cancelMethod={handleCloseLprModal}
          // confirmMethod={downloadQRCode}
          titleText="LPR"
          confirmButtonText="Download"
          confirmButtonStyle="btn-success"
          selectedLane={currentLaneData}
        />}
        {showLaneStatusModal && (
          <LaneStatusModel
            show={showLaneStatusModal}
            event={currentLaneData}
            laneStatus={
              findGateByLaneId(lanesData.gates, currentLaneData?.laneId)
                .laneStatus
            }
            onLoopReset={() =>
              resetHardware(currentLaneData.laneId, 'LOOP_DETECTER')
            }
            onCPUReset={() => resetHardware(currentLaneData.laneId, 'CPU')}
            onClose={handleCloseLaneStatusModel}
            createEvent={handleOpenCreateEventStatusModal}
            closeOpenEvent={handleCloseOpenEvent}
          />
        )}
        {showCreateEventModal && (
          <CreateEventModal
            show={showCreateEventModal}
            onClose={handleCloseCreateEventModal}
            onSubmit={handleSubmitCreateEventModal}
            event={currentLaneData}
            showOpenSessionData={showOpenSessionData}
            disableTimeInput={currentLaneData?.openParkingSession?.entryTime !== null}
          />
        )}
        {showGateControlModal && (
          <GateControlModel
            show={showGateControlModal}
            onClose={gateStatusClose}
            onSubmit={gateStatusSubmit}
            data={gateControlData}
          />
        )}
        {showEditOccupancyModal && (
          <EditOccupanyModel
            show={showEditOccupancyModal}
            onClose={handleCloseEditOccupancyModal}
            onSubmit={handleSubmitEditOccupancyModal}
          />
        )}

        <div
          className="d-flex flex-row"
          style={{ gap: '16px', flexWrap: 'wrap' }}
        >
          <BarChart data={barChartData} />
          <PieChart
            data={adjustedSpeedometerData}
            tablesData={speedometerData}
            title={"Live Occupancy (Total Parking Bays: " + totalOccupancy(locations) + ")"}
            editFunction={setEditOccupancyModal}
            showWarning={currentOccupancy > totalOccupancy(locations) + 10}
          />
        </div>
        {config.DASHBOARD_SHOW_TRANSACTIONS && <Card className="flex-fill w-100">
          <Card.Header style={{ marginBottom: '-40px' }}>
            <Card.Title> Recent Transactions</Card.Title>
          </Card.Header>
          <Card.Body>
            <StyledAGGrid
              rowData={parkingSessions?.filter(s => s.paid != null)}
              columnDefs={homeColumnDefsforTransactions}
              paginated={true} paginationPageSizeNumber={10}
              sortable={false}
              filter={false}
              resizable={false}
              showRefreshButton={false}
            />
          </Card.Body>
        </Card>
        }
      </div>
    </React.Fragment>
  );
};

const getSurchargePct = (locations: ILocation[]) => {
  if (locations && locations[0]) return locations[0].surchargePct;
  return 0;
};
const totalOccupancy = (locations: ILocation[]) => {
  if (locations && locations[0]) return locations[0].totalOccupancy;
  return 0;
};
const emptyOccupancy = (currentOccupancy: number, locations: ILocation[]) => {
  return Math.max(0, totalOccupancy(locations) - currentOccupancy);
};

const occupancy = (currentOccupancy: number) => {
  return Math.max(0, currentOccupancy);
};

export default Home;
