import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { isEmail } from 'react-jhipster';

import ActionButtons from 'app/components/actionButtons';
import {
  getQRCodeImageEntity,
  sendEmailEntity,
} from '../../entities/customer-account/customer-account.reducer';
import { toast } from 'react-toastify';
import StyledAGGrid from 'app/components/styledaggrid';
import { StyledGridDataSource } from 'app/components/styledaggrid/StyledGridDataSource';
import StyledModal from 'app/components/StyledModal';
import {
  createColumnDef,
  createDownloadColumnMapping,
  FilterType,
} from 'app/entities/ColumnDefs';
import html2canvas from 'html2canvas';
import CustomerAccountEditUpdate from './customer-account-edit';
import { formatDate } from 'app/shared/util/date-utils';
import { wrap } from 'lodash';
import ButtonsContainer from 'app/components/buttonsContainer';
import { Title } from 'chart.js';
import TitleContainer from 'app/components/titleContainer';
import { Calendar } from 'app/components/calendar/calendar';
import ListMultiItems from 'app/components/listMultiItems';
import CustomSelectRenderer from 'app/components/CustomSelectRenderer';
import { getColumnDefsForEnvironment } from 'app/config/constants';
import { useConfigContext } from 'app/contexts/ConfigContext';

export const AccountsPage = () => {
  const config = useConfigContext();
  const dispatch = useAppDispatch();

  const loading = useAppSelector(state => state.customerAccount.loading);
  const selectedQRImage = useAppSelector(state => state.customerAccount.links);

  const [loadModal, setLoadModal] = useState(false);
  const [qrModal, setQRModal] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const columnDefs = [
    createColumnDef('accountId', 'Account Id', FilterType.EQUALS),
    createColumnDef('email', 'Email', FilterType.EQUALS),

    createColumnDef('lnp', 'License Plate', FilterType.EQUALS),
    createColumnDef('lnp', 'LPR', FilterType.EQUALS),
    createColumnDef('name', 'Name', FilterType.EQUALS),
    createColumnDef('company', 'Company', FilterType.EQUALS),
    createColumnDef('rateSchedule.name', 'Account Type', FilterType.EQUALS),

    // createColumnDef('zones', 'Zone', FilterType.EQUALS),
    createColumnDef(
      'startTime',
      'Start Time',
      FilterType.DATE_EXTERNAL,
      params => formatDate(params.value)
    ),
    createColumnDef('endTime', 'End Time', FilterType.NONE, params =>
      noEndTimeTransformer(formatDate(params.value))
    ),
    
    {
      field: 'zones',
      headerName: 'Zones',
      filter: false,
      minWidth: 150,
      autoHeight: true,
      // cellRenderer: ListMultiItems,
      cellRenderer: CustomSelectRenderer,
      cellRendererParams: params => ({zones: params?.value?.split(',') || []}),
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      filter: false,
      sortable: false,
      minWidth: 400,
      cellRenderer: ActionButtons,
      cellRendererParams: params => ({
        titles: ['Resend Email', 'View QR', 'Edit', 'Delete'],
        styles: ['btn-info', 'btn-success', 'btn-info', 'btn-danger'],
        icons: ['envelope', 'eye', 'pencil-alt', 'trash'],
        onClicks: [
          sendEmailMethod(params?.data?.id, params?.data?.notes),
          viewQRCodeFn(
            params?.data?.id,
            params?.data?.startTime,
            params?.data?.endTime
          ),
          editMethod(params?.data?.id),
          deleteMethod(params?.data?.id),
        ],
      }),
    },
  ];

  const finalCols = getColumnDefsForEnvironment(columnDefs, config.CUSTOMER_ACCOUNT_COLUMNS);
  const dataSource = useMemo(() => {
    return new StyledGridDataSource(
      'startTime,desc',
      'api/customer-accounts',
      false,
      finalCols
    );
  }, []);

  const viewQRCodeFn =
    (id: string, startTime: String, endTime: String) => () => {
      setQRModal(true);
      dispatch(getQRCodeImageEntity(id));
      return;
    };

  const deleteMethod = (idParam: String) => () => {
    setLoadModal(true);
    setSelectedRowId(idParam);
  };

  const editMethod = (idParam: String) => () => {
    setShowUpdateModal(true);
    setSelectedRowId(idParam);
  };

  const sendEmailMethod = (idParam: string, email: string) => () => {
    if (!isEmail(email)) {
      alert('Invalid Email');
      return;
    }
    dispatch(sendEmailEntity(idParam));
  };

  const noEndTimeTransformer = params => {
    if (params === null || params === undefined || params === '') {
      return 'No end time';
    }
    return params;
  };

  const handleDownload = () => {
    dataSource.downloadAll(createDownloadColumnMapping(finalCols));
  };

  const addRowData = () => {
    setShowCreateModal(true);
  };

  const handleClose = () => {
    setLoadModal(false);
    setShowCreateModal(false);
    setShowUpdateModal(false);
    // setSelectedRowId(null);
    setQRModal(false);
  };

  const confirmDelete = () => {
    dataSource?.delete(selectedRowId);
    setLoadModal(false);
    setShowCreateModal(false);
    setShowUpdateModal(false);
    setSelectedRowId(null);
    toast.success('Deleted Account');
  };

  const downloadQRCode = () => {
    const qrCodeContainer = document.getElementById('qr-code-container');

    html2canvas(qrCodeContainer).then(canvas => {
      const link = document.createElement('a');
      link.download = 'qr-code.png';
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  const handleDateFilter = range => {
    dataSource.handleDateFilter('startTime', range);
  };

  const deleteTitleText = 'Confirm delete operation';
  const deleteDetailedText = 'Are you sure you want to delete this account?';

  return (
    <React.Fragment>
      <div>
        <TitleContainer>
          <div>
            <h3>Registered Accounts</h3>
          </div>
          <ButtonsContainer>
            <Button
              variant="primary"
              onClick={() => dataSource.refresh()}
              className="me-1 btn btn-info"
              disabled={loading}
            >
              <FontAwesomeIcon
                icon="sync"
                spin={loading}
                style={{ marginRight: '5px', width: '12px', height: '12px' }}
              />
              Refresh
            </Button>
            <Button
              variant="primary"
              onClick={addRowData}
              className="me-1 btn btn-primary"
            >
              <FontAwesomeIcon
                icon="plus"
                style={{ marginRight: '5px', width: '12px', height: '12px' }}
              />
              Add
            </Button>
            <Button
              variant="primary"
              onClick={() => handleDownload()}
              className="me-1 btn btn-success"
              disabled={loading}
            >
              <FontAwesomeIcon
                icon="download"
                spin={loading}
                style={{ marginRight: '5px', width: '12px', height: '12px' }}
              />
              Download
            </Button>
            <div style={{ marginTop: '0px' }}>
              <Calendar applyDateFilter={range => handleDateFilter(range)} />
            </div>
          </ButtonsContainer>
        </TitleContainer>
        <StyledAGGrid
          // rowData={customerAccountList}
          columnDefs={finalCols}
          fullWidth={true}
          paginated={true}
          paginationPageSizeNumber={10}
          // download={true}
          dataSource={dataSource}
          // downloadUrl={'/api/transactions/download'}
          // showRefreshButton={true}
          // timeFilterField={'time'}
          rowModelType={'infinite'}
        />
        <StyledModal
          showModal={loadModal}
          confirmMethod={confirmDelete}
          cancelMethod={handleClose}
          titleText={deleteTitleText}
          detailedText={deleteDetailedText}
          confirmButtonText="Delete Record"
          confirmButtonStyle="btn-danger"
        />
        <StyledModal
          showModal={qrModal}
          cancelMethod={handleClose}
          confirmMethod={downloadQRCode}
          titleText="QR Code"
          detailedText={
            <img
              id="qr-code-container"
              src={`data:image/png;base64,${selectedQRImage}`}
              style={{ width: '250px', height: '250px' }}
            />
          }
          confirmButtonText="Download QR Code"
          confirmButtonStyle="btn-success"
        />
        <StyledModal
          showModal={showCreateModal}
          cancelMethod={handleClose}
          titleText="Create new account"
          detailedText={
            <CustomerAccountEditUpdate
              dataSource={dataSource}
              hidePopup={() => setShowCreateModal(false)}
            />
          }
          confirmMethod={handleClose}
          noButtons={true}
        />

        <StyledModal
          showModal={showUpdateModal}
          cancelMethod={handleClose}
          titleText="Update account"
          detailedText={
            <CustomerAccountEditUpdate
              dataSource={dataSource}
              id={selectedRowId}
              hidePopup={() => setShowUpdateModal(false)}
            />
          }
          confirmMethod={handleClose}
          noButtons={true}
        />
      </div>
    </React.Fragment>
  );
};

export default AccountsPage;
