import React from "react";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";

const ZoomableImageWithFallback = ({
  imageUrl,
  fallbackUrl,
  isError,
  onError,
  zoomScale = 2,
  alt = "Image",
  containerClass = "",
  style = {},
}: {
  imageUrl: string;
  fallbackUrl: string;
  isError: boolean;
  onError: () => void;
  zoomScale?: number;
  alt?: string;
  containerClass?: string;
  style?: React.CSSProperties;
}) => {
  const imageStyles: React.CSSProperties = {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
    border: "none",
    ...style, // Allow external styles to override
  };

  return (
    <div className={containerClass} style={{ textAlign: "center", ...style }}>
      {isError ? (
        <img
          src={fallbackUrl}
          onError={onError}
          alt={alt}
          style={imageStyles}
        />
      ) : (
        <>
        <InnerImageZoom
          src={imageUrl}
          onError={onError}
          zoomScale={zoomScale}
          alt={alt}
          style={imageStyles}
        />
        <img
            src={imageUrl}
            onError={onError}
            alt="Fallback Checker"
            style={{ display: "none" }} // Hide fallback checker image
          />
        </>
      )}
    </div>
  );
};

export default ZoomableImageWithFallback;
