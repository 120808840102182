import dayjs from 'dayjs';
import { IRateSchedule } from './rate-schedule.model';

export interface IValidation {
  id?: string;
  email?: string | null;
  amount?: number | null;
  amountCurrency?: string | null;
  startTime?: string | null;
  endTime?: string | null;
  isUsed?: boolean | null;
  rateScheduleId?: number|null;
  rateSchedule?:IRateSchedule | null;
  count?: number | null;
  description?: string | null;
  usedIndexes? : number[] | null;
  multipleEntries? : boolean | null;
  batchId? : string | null;
}

export const defaultValue: Readonly<IValidation> = {
  isUsed: false,
};
